import { Injectable, inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "../_services/auth.service";

@Injectable({
    providedIn: 'root'
})

class PermissionsService {
    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    // ======================================================================== //
    // ================================ USER ================================== //
    // ======================================================================== //

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        /* IF: has user access token(in local storage) */
        if (this.authService.getUserAccessToken()) {
            return true;
        }

        /* ELSE: */
        this.router.navigate(['/login']);
        // Keep line below here for future, when behaviour for login is confirmed
        // this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
        // ^^ When user is prompted to login and does it, they will be redirected to where they left off.

        return false;
    }


    // ======================================================================== //
    // ============================== CONTENT ================================= //
    // ======================================================================== //
    canActivateNew(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const standardUrlPath = state.url.split('/');
        const adminRole = this.authService.getUserRole();
        standardUrlPath.pop();

        if (adminRole === 'ROLE_MAKER_ADMIN' || adminRole === 'ROLE_SUPER_ADMIN') {
            return true;
        }

        this.router.navigate([`${standardUrlPath.join('/')}/listing`]);
        return false;
    }

    canActivateExisting(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const standardUrlPath = state.url.split('/');
        const adminRole = this.authService.getUserRole();
        standardUrlPath.pop();

        if (adminRole === 'ROLE_MAKER_ADMIN' || adminRole === 'ROLE_CHECKER_ADMIN' || adminRole === 'ROLE_SUPER_ADMIN') {
            return true;
        }
        this.router.navigate([`${standardUrlPath.join('/')}/listing`]);
        return false;
    }

    canActivateListing(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const adminRole = this.authService.getUserRole();

        if (adminRole === 'ROLE_MAKER_ADMIN' || adminRole === 'ROLE_CHECKER_ADMIN' || adminRole === 'ROLE_SUPER_ADMIN') {
            return true;
        }
        this.router.navigate(['/login']);
        return false;
    }

    canActivateAssetLibrary(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const adminRole = this.authService.getUserRole();

        if (adminRole === 'ROLE_MAKER_ADMIN' || adminRole === 'ROLE_CHECKER_ADMIN' || adminRole === 'ROLE_SUPER_ADMIN') {
            return true;
        }
        this.router.navigate(['/login']);
        return false;
    }

    canActivatePendingApproval(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const adminRole = this.authService.getUserRole();

        if (adminRole === 'ROLE_CHECKER_ADMIN' || adminRole === 'ROLE_SUPER_ADMIN') {
            return true;
        }
        this.router.navigate(['/login']);
        return false;
    }

    // ======================================================================== //
    // =============================== OTHERS ================================= //
    // ======================================================================== //
    canActivateReport(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const adminRole = this.authService.getUserRole();

        if (adminRole === 'ROLE_SECURITY_ADMIN') {
            this.router.navigate(['/manage-admins/all-admins']);
            return false;
        }
        return true;
    }

    canActivateManagePartner(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const adminRole = this.authService.getUserRole();

        if (adminRole === 'ROLE_MAKER_ADMIN' || adminRole === 'ROLE_CHECKER_ADMIN' || adminRole === 'ROLE_SUPER_ADMIN') {
            return true;
        }
        this.router.navigate(['/login']);
        return false;
    }

    canActivateManageAdmin(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const adminRole = this.authService.getUserRole();

        if (adminRole === 'ROLE_SECURITY_ADMIN' || adminRole === 'ROLE_SUPER_ADMIN') {
            return true;
        }
        this.router.navigate(['/login']);
        return false;
    }

    canActivateAuditTrail(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const adminRole = this.authService.getUserRole();

        if (adminRole === 'ROLE_SECURITY_ADMIN' || adminRole === 'ROLE_SUPER_ADMIN') {
            return true;
        }
        this.router.navigate(['/login']);
        return false;
    }
}

export const userAuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(PermissionsService).canActivate(next, state);
};

export const newContentAuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(PermissionsService).canActivateNew(next, state);
};

export const existingContentAuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(PermissionsService).canActivateExisting(next, state);
};

export const listingContentAuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(PermissionsService).canActivateListing(next, state);
};

export const assetLibraryAuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(PermissionsService).canActivateAssetLibrary(next, state);
};

export const pendingApprovalAuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(PermissionsService).canActivatePendingApproval(next, state);
};

export const reportAuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(PermissionsService).canActivateReport(next, state);
};

export const managePartnerAuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(PermissionsService).canActivateManagePartner(next, state);
};

export const manageAdminAuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(PermissionsService).canActivateManageAdmin(next, state);
};

export const auditTrailAuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(PermissionsService).canActivateAuditTrail(next, state);
};